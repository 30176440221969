import React from "react"
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  ButtonGroup,
} from "react-bootstrap"
import Layout from "../components/layout"
import { VerticalBar } from "../components/sitewide/VerticalBar"
import "../assets/css/theme/insights.scss"
import ngl_select from "../images/stock/ngl_select.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { StaticQuery, graphql } from "gatsby"
import {
  faUser,
  faCalendar,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons"
import { TransitionLink } from "../components/animation/TransitionLink"
import { ensurePublicURL } from "../components/general"

const IndexPage = () => (
  <Layout
    title="Gravitate - Recent Insights."
    description="Some of our articles and thoughts from our industry experts."
    image={ngl_select}
  >
    <VerticalBar positionFixed hideConnect title="INSIGHTS" />
    <Container fluid className="p-0 outer-wrapper">
      <Row>
        <Col lg={7} xs={12} className="pr-0">
          <div className=" insight-accent-box" />
          <div className="posts-container">
            <div className="position-relative">
              <StaticQuery
                query={graphql`
                  query {
                    allStrapiArticles {
                      edges {
                        node {
                          strapiId
                          published_at
                          title
                          excerpt
                          categories {
                            name
                          }
                          author {
                            name
                          }
                          image {
                            publicURL
                          }
                        }
                      }
                    }
                  }
                `}
                render={data => (
                  <Insights articles={data.allStrapiArticles.edges} />
                )}
              />
            </div>
          </div>
        </Col>
        <Col lg={5} xs={12} className="p-0">
          <div className="fixed-outer">
            <div className="right-insight-display">
              <div className="fixed-column-dec" />
              <div
                style={{ backgroundImage: `url(${ngl_select})` }}
                className="img-container"
              >
                <div className="text-container p-5">
                  <h1 className="text-right">OUR LATEST INSIGHTS</h1>
                  <h4 className="text-right pt-3">
                    Gravitate provides AI-enabled collaboration, automation, and
                    decision support software for energy and commodity
                    organizations.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </Layout>
)
const Insights = ({ articles }) => {
  function compare(a, b) {
    if (a.node.published_at > b.node.published_at) return -1
    if (a.node.published_at < b.node.published_at) return 1
    return 0
  }
  const sortedArticles = articles.sort(compare)
  return sortedArticles.map((article, i) => {
    return <InsightPost article={article} key={`article__${i}`} />
  })
}
const InsightPost = ({ article }) => {
  const path =
    article.node.strapiId === 17 ? "gravitate-caseys" : article.node.strapiId

  const articleImage = ensurePublicURL(article.node.image)

  return (
    <div className="position-relative">
      <h2 className="text-left pt-5">
        <TransitionLink className="insight-title-link" to={`/article/${path}`}>
          {article.node.title}
        </TransitionLink>
      </h2>
      <div className="post-meta-container mt-3">
        <ButtonGroup aria-label="Post Meta">
          <Button variant="secondary">{article.node.published_at}</Button>
          <Button variant="secondary">Categories:</Button>
          {article.node.categories &&
            article.node.categories.map((c, i) => (
              <Button key={i} variant="secondary">
                {c.name}
              </Button>
            ))}
        </ButtonGroup>
      </div>
      <div className="text-left">
        <div
          style={{
            background: "url(" + articleImage.publicURL + ")",
            backgroundSize: "contain",
            backgroundPosition: "left",
            backgroundRepeat: "no-repeat",
          }}
          className="insight-image mt-3"
        />
      </div>
      <div className="flex-horizontal mt-3">
        <div>
          <FontAwesomeIcon icon={faUser} />
          <span className="post-meta">{article.node.author?.name}</span>
        </div>
        <div>
          <FontAwesomeIcon className="ml-2" icon={faCalendar} />
          <span className="post-meta">{article.node.published_at}</span>
        </div>
      </div>
      <Row className="mt-3">
        <Col lg={11} xs={12}>
          <div className="text-left post-excerpt">{article.node.excerpt}</div>
        </Col>
      </Row>
      <div className="text-left mt-3">
        <TransitionLink to={`/article/${path}`} className="read-more ">
          READ MORE
          <FontAwesomeIcon size="lg" className="ml-3" icon={faArrowRight} />
        </TransitionLink>
      </div>
      <div className="mt-4">
        <hr />
      </div>
    </div>
  )
}
export default IndexPage
